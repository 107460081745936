<template>
  <div class="max-w-7xl m-auto py-20 pt-[10rem]">
    <div class="w-max m-auto text-3xl lg:text-6xl text-[#18479E] lg:mt-[6rem] lg:hidden">
      Expectation <br /><span class="text-[#EC027C]">meets reality</span>
    </div>
    <div class="relative">
      <!--mobile version-->
      <div class="lg:hidden mb-[2rem]">
        <div class="flex justify-center items-center">
          <div class="w-[12rem] relative">
            <img
              src="@/assets/shore/practicalLearning.png"
              alt="practical_learning"
            />
            <div
              class="absolute bottom-4 text-white font-bold ssm:text-xs text-sm ssm:left-6 left-8"
            >
              Practical learning
            </div>
          </div>

          <div class="w-[12rem] relative mt-12">
            <img
              src="@/assets/shore/immersiongs_programm.png"
              alt="Immersion program"
            />
            <div
              class="absolute bottom-4 text-white font-bold ssm:text-xs text-sm ssm:left-6 left-8"
            >
              Immersion programs
            </div>
          </div>
        </div>

        <div class="flex justify-center items-center">
          <div class="w-[12rem] relative">
            <img
              src="@/assets/shore/webinar_industry.png"
              alt="practical_learning"
            />
            <div
              class="absolute bottom-4 font-bold text-white ssm:text-xs text-sm ssm:left-6 left-8"
            >
              Webinars with <br />
              industry experts
            </div>
          </div>

          <div class="w-[12rem] relative mt-12">
            <img
              src="@/assets/shore/passionDriven.png"
              alt="Immersion program"
            />
            <div
              class="absolute bottom-4 text-white font-bold ssm:text-xs text-sm ssm:left-6 left-8"
            >
              Passion-driven workshops and <br />
              contests
            </div>
          </div>
        </div>

        <div class="flex justify-center items-center">
          <div class="w-[12rem] relative">
            <img
              src="@/assets/shore/global_networking.png"
              alt="globalNetworking"
            />
            <div
              class="absolute bottom-4 text-white font-bold ssm:text-xs text-sm ssm:left-6 left-8"
            >
              Global networking <br />
              opportunities
            </div>
          </div>

          <div class="w-[12rem] relative mt-12">
            <img
              src="@/assets/shore/online_and_offline.png"
              alt="online_and_offline"
            />
            <div
              class="absolute text-white bottom-4 font-bold ssm:text-xs text-sm ssm:left-6 left-8"
            >
              Online and <br />
              offline events
            </div>
          </div>
        </div>

        <div class="flex justify-center items-center">
          <div class="w-[12rem] relative">
            <img
              src="@/assets/shore/wellness_activities.png"
              alt="wellnessActivities"
            />
            <div
              class="absolute bottom-4 text-white font-bold ssm:text-xs text-sm ssm:left-6 left-8"
            >
              Wellness activities
            </div>
          </div>

          <div class="w-[12rem] relative mt-12">
            <img
              src="@/assets/shore/industryExposure.png"
              alt="industryExposure"
            />
            <div
              class="absolute text-white bottom-4 font-bold ssm:text-xs text-sm ssm:left-6 left-8"
            >
              Industry exposure
            </div>
          </div>
        </div>
      </div>
      <!--mobile version-->
      <!--desktop verison-->
      <div class="relative justify-between mt-[-5rem] mb-[5rem] hidden lg:flex">
        <div class="">
          <div class="mb-[20rem]">
            <div class="flex">
              <div class="relative scroll-container">
                <img
                  src="@/assets/shore/practicalLearning.png"
                  alt="practicalLearning"
                  class="w-[15rem] bg-gradient-to-b form-white to-black"
                />
                <div class="absolute bottom-[30%] font-semibold right-[30%] text-white">
                  Practical learning
                </div>
              </div>
              <div class="relative mt-[6rem] scroll-container">
                <img
                  src="@/assets/shore/immersiongs_programm.png"
                  alt="immersingProgram"
                  class="w-[15rem]"
                />
                <div class="absolute bottom-[10%] text-center font-semibold right-[16%] text-white">
                  Immersion programs
                </div>
              </div>
            </div>
            <div class="absolute bottom-[10%] scroll-container">
              <div class="relative">
                <img
                  src="@/assets/shore/webinar_industry.png"
                  alt="practicalLearning"
                  class="w-[15rem]"
                />
                <div class="absolute bottom-[10%] font-semibold text-center left-[20%] text-white">
                  Webinars with <br />
                  industry experts
                </div>
              </div>
            </div>
            <div class="absolute left-[22%] bottom-[-2%] scroll-container">
              <div class="relative mt-[6rem]">
                <img
                  src="@/assets/shore/passionDriven.png"
                  alt="immersingProgram"
                  class="w-[15rem]"
                />
                <div class="absolute bottom-[10%] font-semibold text-center left-[15%] text-white">
                  Passion-driven <br />
                  workshops and contests
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-max m-auto text-3xl lg:text-6xl text-[#18479E] text-center">
          Expectation <br /><span class="text-[#EC027C] text-nowrap">meets reality</span>
        </div>
        <div>
          <div class="mb-[20rem]">
            <div class="flex justify-between">
              <div class="relative mt-[6rem] scroll-container">
                <img
                  src="@/assets/shore/global_networking.png"
                  alt="practicalLearning"
                  class="w-[15rem]"
                />
                <div class="absolute bottom-[10%] text-white text-center font-semibold right-[22%]">
                  Global networking <br />
                  opportunities
                </div>
              </div>
              <div class="relative scroll-container">
                <img
                  src="@/assets/shore/online_and_offline.png"
                  alt="immersingProgram"
                  class="w-[15rem]"
                />
                <div class="absolute bottom-[30%] font-semibold text-center right-[30%] text-white">
                  Online and <br />offline events
                </div>
              </div>
            </div>
            <div class="absolute bottom-0 scroll-container">
              <div class="relative">
                <img
                  src="@/assets/shore/wellness_activities.png"
                  alt="practicalLearning"
                  class="w-[15rem]"
                />
                <div class="absolute bottom-[10%] text-center font-semibold left-[22%] text-white">
                  Wellness activities
                </div>
              </div>
            </div>
            <div class="absolute right-0 bottom-[13%] scroll-container">
              <div class="relative mt-[6rem]">
                <img
                  src="@/assets/shore/industryExposure.png"
                  alt="immersingProgram"
                  class="w-[15rem]"
                />
                <div class="absolute bottom-[10%] text-center font-semibold left-[20%] text-white">
                  Industry exposure
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--desktop verison-->
    </div>
  </div>
</template>

<script>
export default {
  name: "expectionView",
  
  
};
</script>

<style scoped>


</style>
